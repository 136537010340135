<template>
  <div class="mymap" id="container"></div>
</template>

<script>
import AMap from 'AMap'

export default {
  data() {
    return {
      map: {},
      rectangle: {},
      circle:null,
      polygon:null,
      polyline:null,
      lineArr: [],
      editorColor: {
        1: {
          strokeColor: '#059370',
          fillColor: '#9ed09d'
        },
        2: {
          strokeColor: '#EA546D',
          fillColor: '#de7171'
        },
        3: {
          strokeColor: '#71076e',
          fillColor: '#b374b1'
        }
      },
      title: ''
    }
  },

  methods: {
    loadmap() {
      this.map = new AMap.Map('container', {
        mapStyle: 'amap://styles/fresh',
        zoom: 12
      })
    },
    //回显
    viewMask(row) {
      // console.log('回显',row)
      this.map.clearMap()
      this.selectObj = JSON.parse(row.area)
      let selectObj = JSON.parse(row.area)
      let self = this
      let color = self.editorColor[!row.color?1:Number(row.color)]
      //根据areaType值调用不同api展示不同矢量图，1画圆2矩形3多边形4线段
      if(row.locationType===1){
        let latLng = new AMap.LngLat(selectObj.lng,selectObj.lat);
        // let latLng = new AMap.LngLat(selectObj[1],selectObj[0]);
        this.circle = new AMap.Circle({
          center: latLng,
          radius:selectObj.radius, //半径
          // radius:selectObj[2], //半径
          borderWeight: 3,
          strokeColor:color.strokeColor, //#FF33FF,red
          strokeOpacity: 1,
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          strokeStyle: 'solid',
          strokeDasharray: [10, 10], 
          fillColor: color.fillColor,
          zIndex: 50,
        })
        this.circle.setMap(this.map)
        this.map.setFitView([ this.circle ])
      }else if(row.locationType===2){
        let bounds = {}
        let southWest = new AMap.LngLat(selectObj[0].lng, selectObj[0].lat)
        let northEast = new AMap.LngLat(selectObj[2].lng, selectObj[2].lat)
        bounds = new AMap.Bounds(southWest, northEast)
        this.rectangle = new AMap.Rectangle({
          bounds: bounds,
          strokeColor: color.strokeColor,
          strokeOpacity: 0.5,
          strokeWeight: 6,
          fillColor: color.fillColor,
          fillOpacity: 0.5,
          strokeStyle: 'dashed'
        })
        this.rectangle.setMap(this.map)
        this.map.setFitView([this.rectangle])
      }else if(row.locationType===3){
        let newArr = []
      /**转换格式 */
      selectObj.map(item=>{
        newArr.push([item.lng,item.lat])
      })
        this.polygon = new AMap.Polygon({
          path: newArr,
          strokeColor: color.strokeColor,
          strokeOpacity: 0.5,
          strokeWeight: 6,
          fillColor: color.fillColor,
          fillOpacity: 0.5,
          strokeStyle: 'dashed'
        })
        this.polygon.setMap(this.map)
        this.map.setFitView([this.polygon])
      }else if(row.areaType===4){
        this.polyline = new AMap.Polyline({
          path: selectObj,
          strokeColor: color.strokeColor,
          strokeOpacity: 0.5,
          strokeWeight: 6,
          fillColor: color.fillColor,
          fillOpacity: 0.5
        })
        this.polyline.setMap(this.map)
        this.map.setFitView([this.polyline])
      }
      
      
    },
    clearMap() {
      this.map.clearMap()
      this.selectObj = null
      this.rectangle = null
    }
  },
  mounted() {
    this.loadmap() // 加载地图和相关组件;
  }
}
</script>

<style>
.mymap {
  width: 100%;
  height: 63vh;
}
</style>
