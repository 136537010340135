var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "formContent" }, [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "dialog-form add-enclousre-form",
              attrs: {
                "label-width": "140px",
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "left"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: " 区域名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.companyDisabled,
                      expression: "!companyDisabled"
                    }
                  ],
                  attrs: { label: "公司车队", prop: "getGroupIds" }
                },
                [
                  _c("companySelectForSearch", {
                    ref: "companySelectForSearch",
                    attrs: { multiple: false, searchable: true },
                    on: { getValue: _vm.getGroupIds }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.companyDisabled,
                      expression: "companyDisabled"
                    }
                  ],
                  attrs: { label: "公司车队", prop: "getGroupIds" }
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", type: "textarea" },
                    model: {
                      value: _vm.valueNameAdd,
                      callback: function($$v) {
                        _vm.valueNameAdd = $$v
                      },
                      expression: "valueNameAdd"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "报警类型", prop: "alarmType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        disabled: _vm.typeDisabled
                      },
                      model: {
                        value: _vm.form.alarmType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "alarmType", $$v)
                        },
                        expression: "form.alarmType"
                      }
                    },
                    _vm._l(_vm.alarmTypeList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区域颜色" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeColorType },
                      model: {
                        value: _vm.form.color,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "color", $$v)
                        },
                        expression: "form.color"
                      }
                    },
                    _vm._l(_vm.colorList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否限制时间" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.timeChange },
                      model: {
                        value: _vm.form.timeChose,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "timeChose", $$v)
                        },
                        expression: "form.timeChose"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.timeChose === 1,
                      expression: "form.timeChose === 1"
                    }
                  ],
                  attrs: { label: "开始时间" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "开始时间",
                      align: "right",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.beginTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "beginTime", $$v)
                      },
                      expression: "form.beginTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.timeChose === 1,
                      expression: "form.timeChose === 1"
                    }
                  ],
                  attrs: { label: "结束时间" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "结束时间",
                      align: "right",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "endTime", $$v)
                      },
                      expression: "form.endTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否限制速度" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.speedChange },
                      model: {
                        value: _vm.form.speedChoose,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "speedChoose", $$v)
                        },
                        expression: "form.speedChoose"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.speedChoose === 1,
                      expression: "form.speedChoose === 1"
                    }
                  ],
                  attrs: { label: "限速值" }
                },
                [
                  _c("el-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.form.speed,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "speed",
                          _vm._n(typeof $$v === "string" ? $$v.trim() : $$v)
                        )
                      },
                      expression: "form.speed"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.speedChoose === 1,
                      expression: "form.speedChoose === 1"
                    }
                  ],
                  attrs: { label: "夜间最大速度" }
                },
                [
                  _c("el-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.form.maxSpeedNight,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "maxSpeedNight",
                          _vm._n(typeof $$v === "string" ? $$v.trim() : $$v)
                        )
                      },
                      expression: "form.maxSpeedNight"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否平台报警" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changePaAlarmFun },
                      model: {
                        value: _vm.form.paAlarm,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "paAlarm", $$v)
                        },
                        expression: "form.paAlarm"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.paAlarm === 1,
                      expression: "form.paAlarm === 1"
                    }
                  ],
                  attrs: { label: "平台报警车辆" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        multiple: "",
                        filterable: "",
                        remote: "",
                        "collapse-tags": "",
                        placeholder: "请输入关键词",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.loading,
                        "multiple-limit": _vm.limit,
                        "reserve-keyword": true
                      },
                      model: {
                        value: _vm.form.vehicleNos,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "vehicleNos", $$v)
                        },
                        expression: "form.vehicleNos"
                      }
                    },
                    _vm._l(_vm.options, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.cph, value: item[_vm.label] }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { disabled: _vm.companyDisabled },
                on: { change: _vm.drawMap },
                model: {
                  value: _vm.form.locationType,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "locationType", $$v)
                  },
                  expression: "form.locationType"
                }
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("圆形")]),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("矩形")]),
                _c("el-radio", { attrs: { label: 3 } }, [_vm._v("多边形")])
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "clearMap",
                attrs: {
                  type: "primary",
                  size: "small",
                  disabled: !_vm.form.area
                },
                on: { click: _vm.clearType }
              },
              [_vm._v("点击清除")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "definedMap", attrs: { id: "container" } })
      ])
    ]),
    _c("div", { staticClass: "dialog-btn" }, [
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", size: "small" },
              on: {
                click: function($event) {
                  return _vm.clsoeDia(true)
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onSave }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }